<template>
    <div class="mn_doc">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Documentation</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Aplikasi Aksara Ulu Sumsel (AKAS) - 03 Juli 2024
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/akasplaystore.png" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Tampilan Aksara Ulu Sumsel (AKAS) di Playstore
                    </h4>
                    <p class="nt-4">
                        AKAS, atau Aplikasi Aksara Ulu Sumatera Selatan, adalah aplikasi cerdas yang mampu mengenali dan
                        mengalih-aksarakan gambar atau huruf aksara Ulu Sumatera Selatan menjadi aksara Latin. AKAS
                        dapat mengenali aksara dari hasil foto kamera, tulisan tangan, maupun gambar yang diunggah ke
                        aplikasi. Selain itu, aplikasi ini juga dapat mengalih-aksarakan teks huruf alfabet menjadi
                        aksara Ulu Sumatera Selatan. Dirancang untuk kemudahan dan kecepatan, AKAS memungkinkan pengguna
                        untuk mengalih-aksarakan teks dalam bentuk kata maupun paragraf dengan mudah. Dengan fitur ini,
                        aplikasi AKAS membantu melestarikan aksara Ulu Sumatera Selatan dengan memudahkan pengguna dalam
                        membaca dan menulis aksara tersebut. Aplikasi ini juga dilengkapi dengan beberapa menu edukatif
                        yang bermanfaat, seperti daftar aksara Ulu dan informasi sejarah aksara Ulu Sumatera Selatan.
                        Aplikasi ini dikembangkan oleh tim Intelligent Systems Research Group Universitas Bina Darma
                        bekerja sama dengan Dinas Kebudayaan dan Pariwisata Sumatera Selatan, Komunitas Pecinta Aksara
                        Ulu Sumatera Selatan, dan Dinas Kearsipan Sumatera Selatan. AKAS tersedia secara gratis dan bisa
                        digunakan oleh siapa saja yang ingin mengenal lebih dalam tentang aksara Ulu Sumatera Selatan.
                        <a href="https://play.google.com/store/apps/details?id=id.sumsel.akas&hl=en-ID">Link</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Photo Bersama dalam Acara Workshop FGD Pengenalan dan Penggunaan Aplikasi Aksara Ulu Oleh
                            ISRG Universitas Bina Darma - 14 November 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/photobersamaworkshop02.jpg" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Dokumentasi Photo Bersama Tim ISRG dan Peserta Workshop
                    </h4>
                    <p class="nt-4">
                        Dokumentasi ini berupa photo bersama dengan tim ISRG dan anggota workshop dari Dinas Kearsipan
                        Provinsi Sumatera Selatan, Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan, Dinas
                        Perpustakaan Provinsi Sumatera Selatan, Perwakilan dari Komunitas Pecinta Aksara Ulu dan
                        Orang-Orang penting di Universitas Bina Darma.
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersamaworkshop01.jpg " class="dc_img04">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Photo-Photo Bersama - 29 Oktober 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/photobersama01.jpg" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Dokumentasi Photo-Photo Bersama Anggota ISRG dalam
                        Berbagai Momen</h4>
                    <p class="nt-4">
                        Banyak momen-momen photo bersama yang dilakukan oleh anggota ISRG. Seperti ketika sedang makan
                        bersama di sebuah tempat makan, photo bersama di lantai 2 Universitas Bina Darma dan photo
                        bersama di Perpustakaan Universitas Bina Darma.
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersama02.jpg " class="dc_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersama03.jpg" class="dc_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersama04.jpg" class="dc_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersama05.jpg" class="dc_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersama06.jpg" class="dc_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Penyerahan Baju ISRG kepada Mahasiswa Riset - 24 Oktober 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/bajuisrg.jpg" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Dokumentasi Penyerahan Baju ISRG kepada Beberapa
                        Mahasiswa Riset</h4>
                    <p class="nt-4">
                        Baju-baju ISRG yang diserahkan kepada mahasiswa riset dilakukan untuk menunjukkan identitas diri
                        sebagai anggota yang tergabung ke dalam Intelligent Systems Research Group.
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajutri.jpg " class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuaji.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajudio.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuirfan.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuahmad.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajunia.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuraffi.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajurido.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajurizki.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuselvi.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajutia.jpg" class="dc_img03">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/serahbajuwulan.jpg" class="dc_img03">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website - 21 September 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/transliterasiaksara.png" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Tampilan Hasil Transliterasi Aksara Latin ke Aksara Ulu
                        di Aplikasi Transliterasi Aksara Berbasis Website</h4>
                    <p class="nt-4">
                        Dari tampilan tersebut dapat dilihat hasil terjemahan dari huruf latin menjadi huruf aksara ulu.
                        Meskipun teks tersebut pendek ataupun panjang, aplikasi ini mampu menerjemahkannya dengan baik.
                        Untuk video tutorial penggunaan aplikasi ini dapat dilihat melalui video tutorial yang tertera.
                        Berikut link Aplikasi Transliterasi Aksara Latin ke Aksara Ulu. <a
                            href="https://nulisaksaraulu.my.id/">Link</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-5">
            <h4 class="my-3">Video Tutorial Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website</h4>
            <div class="row">
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/puZwdqgdmwo?si=qF6iiR108zyhoUgP" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Aplikasi Alih Aksara Dokumen Aksara Ulu (Komering) Berbasis Website - 16 September 2023
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/alihaksara.png" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Tampilan Aplikasi Alih Aksara Dokumen Aksara Ulu
                        (Komering) di Browser Chrome</h4>
                    <p class="nt-4">
                        Aplikasi Alih Aksara Dokumen Aksara Ulu (Komering) berbasis website merupakan aplikasi yang
                        digunakan untuk mengidentifikasi huruf-huruf aksara ulu yang terdapat di dalam gambar. Adapun
                        video tutorial penggunaan aplikasi tersebut dapat dilihat melalui video tutorial yang ada.
                        Berikut link untuk mengakses Aplikasi Alih Aksara Dokumen Aksara Ulu. <a
                            href="https://enggine.nulisaksaraulu.my.id/smart-prediction">Link</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-5">
            <h4 class="my-3">Video Tutorial Aplikasi Alih Aksara Dokumen Aksara Ulu (Komering) Berbasis Website</h4>
            <div class="row">
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/iGAOtZvhf0k?si=fTOsYIDqNuwEHXYV" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card mt-5">
                        <div class="card-body">
                            Aplikasi Aksara Ulu (Kaganga) versi Mobile - 10 Oktober 2022
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-2">
            <div class="row">
                <div class="jmb_img col-md-7 my-2">
                    <img src="@/assets/aksaraulumobile.png" alt="logo" class="dc_img01">
                </div>
                <div class="jmb_text col-md-5">
                    <h4 class="jmb_title font-weight-light py-0">Aplikasi Aksara Ulu (Kaganga) yang Sudah Tersedia di
                        Google Playstore</h4>
                    <p class="nt-4">
                        Aksara Ulu (Kaganga) adalah suatu aplikasi yang digunakan untuk mendeteksi dan mencari tau
                        kemiripan dari suatu tulisan yang diambil menggunakan kamera ataupun di tulis secara langsung
                        dengan huruf asli dari aksara (Kaganga). Tampilan gambar disamping merupakan tampilan aplikasi
                        Aksara Ulu (Kaganga) yang bisa diunduh di Google Playstore. Selain itu juga, video tutorial
                        penggunaan aplikasi Aksara Ulu (Kaganga) berbasis mobile juga dapat dilihat melalui video
                        tutorial yang tersedia.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-5">
            <h4 class="my-3">Video Tutorial Menggunakan Aplikasi Aksara Ulu (Kaganga) Berbasis Android</h4>
            <div class="row">
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/ckD206rQ59s?si=KXuhXcdrb4TpI-9P" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'DocumenTation',
    components: {
        NavBar,
        FooTer,
    },
}
</script>
<style scoped>
.mn_doc {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.card-body {
    font-size: 20px;
    font-weight: bold;
    background-color: #26517c;
    color: white;
    border: 3px solid #ffd5b8;
}

.dc_img01 {
    width: 100%;
    height: auto;
    aspect-ratio: 7.5/5;
}

.dc_img02 {
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
}

.dc_img03 {
    max-width: 260px;
    width: 100%;
    height: auto;
    aspect-ratio: 12/16;
    object-fit: cover;
}

.dc_img04 {
    width: 100%;
    height: auto;
}

.jmb_text {
    display: flex;
    flex-direction: column;
}

.jmb_title {
    text-align: left;
}

.nt-4 {
    font-size: 13.5px;
}

@media (min-width: 993px) {
    .jmb_text {
        margin-top: 40px;
    }

    .nt-4 {
        margin-top: 10px;
        text-align: justify;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .jmb_text {
        margin-top: 40px;
    }

    .jmb_title {
        font-size: 25px;
        margin-bottom: 0;
    }

    .nt-4 {
        text-align: justify;
        font-size: 8.5px;
        margin-top: 15px;
    }
}

@media (max-width: 767px) {
    .jmb_title {
        text-align: center;
    }

    .nt-4 {
        margin-top: 10px;
        text-align: justify;
    }
}
</style>