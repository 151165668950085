<template>
  <div class="container my-5">
    <div class="row">
      <div class="jmb_img col-md-7 my-5">
        <img src="@/assets/bigdata.png" alt="logo" class="lg_img">
      </div>
      <div class="jmb_text col-md-5">
        <h1 class="font-weight-light py-0">Intelligent Systems for Big Data</h1>
        <p class="nt-4">
          ISRG fokus mengembangkan sistem cerdas berbasis deep learning maupun machine learning untuk data besar pada
          sistem pendeteksi serangan di jaringan Internet of Thing. Model yang dibuat masih dalam prototipe yang
          nantinya bisa digunakan melengkapi software pendeteksi serangan khususnya untuk platform jaringan internet of
          thing. Selain itu juga beberapa riset yang dilakukan oleh ISRG adalah melakukan prediksi, dan klasifikasi
          untuk
          data besar lainnya seperti data cuaca yang dikaitkan untuk mitigasi bencana dikarenakan anomali cuaca. Selain
          itu juga beberapa big data di bidang kesehatan juga menjadi fokus data yang dianalisis oleh ISRG.
        </p>
      </div>
      <div class="jmb_img col-md-7 my-5">
        <img src="@/assets/lcp.png" alt="logo" class="lg_img">
      </div>
      <div class="jmb_text col-md-5">
        <h1 class="font-weight-light py-0">Intelligent Systems for Local Culture Preservation</h1>
        <p class="nt-4">
          ISRG juga berfokus untuk melestarikan budaya khususnya kebudayaan Sumatera Selatan. Dengan pendekatan sistem
          cerdas beberapa model dan aplikasi terus dikembangkan antara lain aplikasi aksara Ulu Sumatera Selatan dan
          aplikasi untuk alih aksara Sumatera Selatan. Produk yang dihasilkan berupa aplikasi mobile dan web yang dapat
          dimanfaatkan untuk membaca dan alih aksara Ulu dan latin. Selain itu juga ISRG menjadikan machine translator
          bahasa daerah Sumatera Selatan menjadi kajian fokus riset yang dikembangkan. Tujuan utama pengembangan mesin
          penerjemah bahasa daerah Sumatera Selatan ini adalah guna melestarikan bahasa daerah Sumatera Selatan yang
          sangat beragam dan mulai tergerus zaman. ISRG berkomitmen juga mengembangkan topik lain terkait pelestarian
          budaya terus digali dan dikembangkan dengan melibatkan berbagai pihak.
        </p>
      </div>
      <div class="jmb_img col-md-7 my-5">
        <img src="@/assets/rta.png" alt="logo" class="lg_img">
      </div>
      <div class="jmb_text col-md-5">
        <h1 class="font-weight-light py-0">Intelligent Systems for Real-Time Applications</h1>
        <p class="nt-4">
          Fokus ISRG juga terus mengembangkan aplikasi dan model yang dikembangkan dalam penelitian bertahap dalam
          prototype untuk menjadi aplikasi cerdas real-time. Saat ini beberapa model yang sudah dibuat akan diterapkan
          pada alat seperti robot pengenal wajah, dan aplikasi lainnya yang nantinya bisa dimanfaatkan oleh masyarakat
          dan industri.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JumboTron',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lg_img {
  width: 100%;
  aspect-ratio: 7.5/5;
}

h1 {
  text-align: left;
}

.jmb_text {
  display: flex;
  flex-direction: column;
}

.nt-4 {
  font-size: 13.5px;
}

@media (min-width: 993px) {
  .jmb_text {
    margin-top: 40px;
  }

  .nt-4 {
    margin-top: 10px;
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .jmb_text {
    margin-top: 40px;
  }

  h1 {
    font-size: 25px;
    margin-bottom: 0;
  }

  .nt-4 {
    text-align: justify;
    font-size: 8.5px;
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  h1 {
    text-align: center;
  }

  .nt-4 {
    margin-top: 10px;
    text-align: justify;
  }
}
</style>
