<template>
    <div class="mn_vandm">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Vision and Mission</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container my-5">
            <div class="row">
                <!-- Visi Section -->
                <div class="col-md-6">
                    <div class="header_main">
                        <h2 class="hd_01">VISION</h2>
                        <img src="@/assets/header_underline.png" alt="Header Underline Image"
                            class="header_underline img-fluid">
                    </div>
                    <div class="jmb_text my-3 mx-3">
                        <p class="nt-4">
                            Menjadi Kelompok Keahlian yang unggul dan berperan aktif pada pengembangan ilmu pengetahuan
                            dan teknologi sistem cerdas baik level nasional maupun internasional.
                        </p>
                    </div>
                </div>
                <!-- Misi Section -->
                <div class="col-md-6">
                    <div class="header_main">
                        <h2 class="hd_01">MISSION</h2>
                        <img src="@/assets/header_underline.png" alt="Header Underline Image"
                            class="header_underline img-fluid">
                    </div>
                    <div class="container my-5">
                        <div class="row">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Mengembangkan penelitian bidang keilmuan sistem cerdas secara berkelanjutan
                                        untuk meningkatkan daya saing skala nasional dan internasional.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Meningkatkan penelitian kolaboratif multi-disiplin yang berorientasi pada
                                        pengembangan produk sistem cerdas.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Mengembangkan kompetensi sumber daya manusia untuk kelompok keahlian sistem
                                        cerdas yang mampu berkontribusi pada pembangunan bangsa dan mampu bersaing pada
                                        tingkat nasional dan internasional.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Bekerja sama dengan fakultas, dan program studi dalam megupdate kurikulum,
                                        tenaga pengajar dan pendukung akademik yang profesional sesuai kebutuhan dan
                                        perkembangan teknologi.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Melaksanakan program pengabdian kepada masyarakat dengan menerapkan hasil-hasil
                                        dari produk penelitian.
                                    </p>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center my-3">
                                <div class="jmb_img">
                                    <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                                </div>
                                <div class="jmb_text">
                                    <p class="nt-4">
                                        Mengembangkan kerjasama di tingkat nasional dan internasional dalam melaksanakan
                                        tri dharma perguruan tinggi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';

export default {
    name: 'VandM',
    components: {
        NavBar,
        FooTer,
    },
};
</script>

<style scoped>
.mn_vandm {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.header_main {
    margin-top: 30px;
    text-align: center;
}

.header_underline {
    width: 100%;
    max-width: 375px;
    height: auto;
    margin-bottom: 30px;
}

.lg_img {
    width: 70px;
    height: 70px;
    aspect-ratio: 1/1;
}

.jmb_text {
    text-align: justify;
}

.nt-4 {
    margin: 0;
    line-height: 1.5;
}

.d-flex>.jmb_text {
    flex-grow: 1;
    padding-left: 10px;
}

@media (max-width: 768px) {
    .d-flex {
        align-items: flex-start;
    }

    .jmb_text {
        padding-left: 0;
        margin-top: 10px;
    }
}
</style>