<template>
    <div class="mn_dataset">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Dataset</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-3 my-3">
                    <div class="card h-100 mt-5"
                        @click="openExternalLink('https://www.kaggle.com/datasets/ykunang/aksara-komering')">
                        <div class="card-body d-flex justify-content-center align-items-center">
                            <a>Dataset Aksara Ulu</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100 mt-5"
                        @click="openExternalLink('https://drive.google.com/file/d/1ctbSMPnv99h9vtzjH5-Fv-VoROvuPtl7/view?usp=drive_link')">
                        <div class="card-body d-flex justify-content-center align-items-center">
                            <a> Dataset Bahasa Komering-Indonesia</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100 mt-5"
                        @click="openExternalLink('https://drive.google.com/file/d/17g7QapFBWLWVWYt9nkRFqT-vxlQOLJsy/view?usp=drive_link')">
                        <div class="card-body d-flex justify-content-center align-items-center">
                            <a>Dataset Bahasa Lahat-Indonesia</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100 mt-5"
                        @click="openExternalLink('https://docs.google.com/spreadsheets/d/1rarxLEjMNymps2QwXRDGWVpbPMiDPz4h/edit?usp=sharing&ouid=117698546768038666163&rtpof=true&sd=true')">
                        <div class="card-body d-flex justify-content-center align-items-center">
                            <a>Dataset Bahasa Palembang-Indonesia</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'DataSet',
    components: {
        NavBar,
        FooTer,
    },
    methods: {
        openExternalLink(url) {
            window.open(url, '_blank');
        }
    }
}
</script>
<style scoped>
.mn_dataset {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.card {
    font-size: 20px;
    font-weight: bold;
    background-color: #ffd5b8;
    border: 3px solid #26517c;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .2s all ease-in-out;
}

.card:hover {
    transform: translateY(-30px);
    background-color: #fff;
    cursor: pointer;
}
</style>