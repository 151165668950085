<template>
    <div class="mn_partner">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Partner</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container">
            <div class="row mb-5">
                <div class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
                    <div class="image-container">
                        <a href="https://aksaraulu.com/"><img src="@/assets/logoaksara.png"
                                class="icon_partnership pb-3"></a>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 d-flex align-items-center">
                    <p class="text-justify">Perkumpulan Pecinta Aksara Ulu adalah salah satu kumpulan generasi muda yang
                        perduli akan budaya tulis khas daerah Sumatera Selatan yakni Surat Ulu atau Aksara Ulu.
                        Perkumpulan ini di harapakan menjadi wadah bagi masyarakat Sumatera Selatan sebagai pelindung,
                        pelestarian, pengembangan dan pemanfaatan budaya tulis Khas daerah Sumatera Selatan.</p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
                    <div class="image-container">
                        <a href="https://www.instagram.com/disbudparsumsel/"><img src="@/assets/disbudparsumsel.png"
                                class="icon_partnership pb-3"></a>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 d-flex align-items-center">
                    <p class="text-left">Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan bertugas sebagai
                        lembaga
                        pemerintah yang mempromosikan, mengembangkan, dan melestarikan kekayaan budaya serta potensi
                        pariwisata di wilayah tersebut. Mereka aktif dalam merancang dan melaksanakan program-program
                        untuk meningkatkan kunjungan wisata, mempromosikan warisan budaya seperti tarian tradisional,
                        musik, dan kuliner khas Sumatera Selatan, serta menjaga dan membangun infrastruktur pariwisata
                        yang berkelanjutan untuk memajukan ekonomi lokal dan meningkatkan kesadaran akan keberagaman
                        budaya di masyarakat.</p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
                    <div class="image-container">
                        <a href="https://www.instagram.com/dinaskearsipan.provsumsel/"><img src="@/assets/diskear.png"
                                class="icon_partnership pb-3"></a>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 d-flex align-items-center">
                    <p class="text-left">Dinas Kearsipan Provinsi Sumatera Selatan adalah lembaga pemerintah yang
                        bertanggung jawab atas pengelolaan arsip di wilayah Sumatera Selatan. Tugas utama dinas ini
                        meliputi pengumpulan, penyimpanan, pemeliharaan, dan penyediaan akses terhadap arsip-arsip
                        penting yang mencakup dokumen pemerintah, sejarah, dan budaya setempat. Dinas Kearsipan Provinsi
                        Sumatera Selatan berperan dalam menjaga dan melestarikan informasi yang bernilai sejarah,
                        administratif, dan legal untuk generasi mendatang. Dengan menerapkan teknologi modern dan metode
                        pengarsipan yang efisien, mereka memastikan bahwa arsip-arsip tersebut terkelola dengan baik dan
                        mudah diakses oleh publik, peneliti, serta instansi pemerintah. Melalui upaya ini, dinas ini
                        mendukung transparansi, akuntabilitas, dan keberlanjutan pemerintahan serta pelestarian warisan
                        dokumenter Sumatera Selatan.</p>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
                    <div class="image-container">
                        <a href="https://www.instagram.com/dispustakaprovsumsel/"><img
                                src="@/assets/disperpussumsel.png" class="icon_partnership pb-3"></a>
                    </div>
                </div>
                <div class="col-lg-9 col-sm-12 d-flex align-items-center">
                    <p class="text-left">Dinas Perpustakaan Provinsi Sumatera Selatan adalah lembaga pemerintah yang
                        bertanggung jawab atas pengelolaan, pengembangan, dan penyediaan layanan perpustakaan di wilayah
                        Sumatera Selatan. Tugas utama dinas ini mencakup pengumpulan, penyimpanan, dan penyebaran
                        informasi serta literatur dalam berbagai bentuk, baik cetak maupun digital. Mereka berperan
                        penting dalam meningkatkan minat baca dan literasi masyarakat dengan menyediakan akses yang luas
                        terhadap buku, jurnal, majalah, dan sumber-sumber informasi lainnya, serta menyelenggarakan
                        berbagai program dan kegiatan edukatif, seperti seminar, pelatihan, dan diskusi, untuk mendukung
                        pengembangan pengetahuan dan keterampilan masyarakat. Dengan memanfaatkan teknologi informasi
                        dan komunikasi modern, Dinas Perpustakaan Provinsi Sumatera Selatan berkomitmen untuk
                        menyediakan layanan perpustakaan yang efektif dan efisien, menciptakan masyarakat yang cerdas,
                        berpengetahuan, dan berbudaya di Sumatera Selatan.</p>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'PartNer',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_partner {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.icon_partnership {
    max-width: 100%;
    height: auto;
}

p {
    margin-top: 10px;
    font-size: 1rem;
    color: #333;
    text-align: justify;
}
</style>
