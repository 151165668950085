<template>
    <div class="container">
        <div class="row">
            <iframe class="well well-sm col-xs-8"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d498.044018172816!2d104.77016708241594!3d-2.9995246126349238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e3b9df5385cf0a1%3A0x6d0080106b346bc3!2sBina%20Darma%20University!5e0!3m2!1sen!2sid!4v1719563609083!5m2!1sen!2sid"
                height="450" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IFrame',
}
</script>

<style scoped></style>