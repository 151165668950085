<template>
    <div class="homepage">
        <NavBar />
        <CarOusel />
        <div class="header_main container-fluid">
            <h1 class="hd_01">NEWS</h1>
            <img src="@/assets/header_underline.png" alt="Header Underline Image" class="header_underline img-fluid">
        </div>
        <CardsMenu />
        <div class="header_main container-fluid">
            <h1 class="hd_01">RESEARCH FIELD</h1>
            <img src="@/assets/header_underline.png" alt="Header Underline Image" class="header_underline img-fluid">
        </div>
        <JumboTron />
        <div class="header_main container-fluid">
            <h1 class="hd_01">PARTNERSHIP</h1>
            <img src="@/assets/header_underline.png" alt="Header Underline Image" class="header_underline img-fluid">
        </div>
        <IconMenu />
        <div class="header_main container-fluid">
            <h1 class="hd_01">LOCATION</h1>
            <img src="@/assets/header_underline.png" alt="Header Underline Image" class="header_underline img-fluid">
        </div>
        <IFrame />
        <FooTer />
    </div>
</template>



<script>
import NavBar from '@/components/NavBar.vue'
import CarOusel from '@/components/CarOusel.vue'
import JumboTron from '@/components/JumboTron.vue'
import CardsMenu from '@/components/CardsMenu.vue'
import IconMenu from '@/components/IconMenu.vue'
import IFrame from '@/components/IFrame.vue'
import FooTer from '@/components/FooTer.vue'


export default {
    name: 'HoMe',
    components: {
        NavBar,
        CarOusel,
        CardsMenu,
        JumboTron,
        IconMenu,
        IFrame,
        FooTer,
    },
}
</script>

<style scoped>
.header_main {
    margin-top: 90px;
    text-align: center;
}

.header_underline {
    width: 475px;
    height: 47.5px;
    aspect-ratio: 30/3;
    margin-bottom: 90px;
}

.homepage {
    margin-top: 78px;
}

@media (min-width: 511px) and (max-width:1214px) {
    .homepage {
        margin-top: 56px;
    }
}

@media (max-width:509px) {
    .homepage {
        margin-top: 98px;
    }
}
</style>
