<template>
    <div class="mn_haki">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Haki</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container my-5">
            <div class="row">
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            “Dataset Tulisan Tangan Aksara Komering (Aksara Ulu)”. No sertifikat EC00202272176, 5
                            Oktober 2022.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            “Aplikasi Perangkat Lunak Pendeteksi Aksara Komering/Aksara Ulu Berbasis Android”. No
                            sertifikat EC00202273702, 10 Oktober 2022.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            “Aplikasi Transliterasi Aksara Latin Ke Komering/Aksara Ulu Berbasis Web Dan Android”. No
                            sertifikat EC00202384116, 21 September 2023.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'HaKi',
    components: {
        NavBar,
        FooTer,
    },
}
</script>
<style scoped>
.mn_haki {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.lg_img {
    width: 70px;
    height: 70px;
    aspect-ratio: 1/1;
}

.jmb_text {
    text-align: justify;
}

.nt-4 {
    margin: 0;
    line-height: 1.5;
}

.d-flex>.jmb_text {
    flex-grow: 1;
    padding-left: 20px;
}
</style>