<template>
    <div class="mn_ra">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Research Activities</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container my-5">
            <div class="row justify-content-center">
                <div class="jmb_img">
                    <img src="@/assets/researchactivities.png" alt="Image RA" class="img_ra mx-auto d-block">
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'ResearchActivities',
    components: {
        NavBar,
        FooTer,
    },
}
</script>
<style scoped>
.mn_ra {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.img_ra {
    scale: 90%;
    width: 100%;
    aspect-ratio: 9/4;
}

.jmb_img {
    text-align: center;
}
</style>