<template>
  <nav class="navbar navbar-expand-lg fixed-top py-2 px-4" :class="{ 'scrolled': isScrolled }">
    <div class="container-fluid">
      <router-link class="navbar-brand me-auto" :to="{ name: 'HoMe' }">
        <img :src="logoSrc" :class="{ 'alternate-logo': isAlternateLogo }" alt="logo" class="img_logo img-fluid">
      </router-link>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <img :src="logoSrc" :class="{ 'alternate-logo': isAlternateLogo }" alt="logo" class="img_logo img-fluid">
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <router-link class="nav-link mx-lg-2" :to="{ name: 'HoMe' }">Home</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle mx-lg-2" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">About Us</a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" :to="{ name: 'ProFile' }">Profile</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'VandM' }">Vision and Mission</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle mx-lg-2" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">Research</a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" :to="{ name: 'ResearchActivities' }">Research
                    Activities</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'PubliCation' }">Publication</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'HaKi' }">Haki</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'DataSet' }">Dataset</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle mx-lg-2" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">People</a>
              <ul class="dropdown-menu">
                <li><router-link class="dropdown-item" :to="{ name: 'ResearchersProfile' }">Researchers
                    Profile</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'ResearchStudent' }">Research Student</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link mx-lg-2" :to="{ name: 'PartNer' }">Partner</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link mx-lg-2" :to="{ name: 'DocumenTation' }">Documentation</router-link>
            </li>
          </ul>
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
</template>


<script>
import logo from '@/assets/logo_navbar03.png';
import alternateLogo from '@/assets/logo_navbar02.png';
import $ from 'jquery';
// Import Bootstrap secara spesifik
import { Offcanvas } from 'bootstrap';

export default {
  name: 'NavBar',
  data() {
    return {
      logoSrc: logo,
      isAlternateLogo: false,
      isScrolled: false
    }
  },
  mounted() {
    this.updateLogo();
    window.addEventListener('resize', this.updateLogo);

    // jQuery for handling scroll and navbar animation
    $(window).on('scroll', this.handleScroll.bind(this));

    // jQuery for dropdown animation
    $(document).ready(function () {
      // Hover effect for larger screens
      $('.dropdown').hover(function () {
        if ($(window).width() > 991) {
          $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        }
      }, function () {
        if ($(window).width() > 991) {
          $(this).find('.dropdown-menu').first().stop(true, true).slideUp(300);
        }
      });

      // Hover effect for submenu
      $('.dropdown-menu > li').hover(function () {
        if ($(window).width() > 991) {
          $(this).children('.submenu').stop(true, true).slideDown(300);
        }
      }, function () {
        if ($(window).width() > 991) {
          $(this).children('.submenu').stop(true, true).slideUp(300);
        }
      });

      // Handling dropdown clicks on small screens
      if ($(window).width() < 992) {
        // Handle click for first level dropdown
        $('.dropdown > .dropdown-toggle').on('click', function (e) {
          e.preventDefault();
          const $submenu = $(this).next('.dropdown-menu');
          if ($submenu.is(':visible')) {
            $submenu.stop(true, true).slideUp(300); // Close current menu
          } else {
            $(this).closest('.navbar-nav').find('.dropdown-menu').slideUp(300); // Close other open menus
            $submenu.stop(true, true).slideToggle(300); // Toggle current menu
          }
        });
      }
    });

    // Inisialisasi ulang Offcanvas pada setiap halaman refresh atau akses
    const offcanvas = document.getElementById('offcanvasNavbar');
    if (offcanvas) {
      console.log('Offcanvas element found:', offcanvas);
      const offcanvasInstance = new Offcanvas(offcanvas);
      offcanvasInstance.hide();
    } else {
      console.log('Offcanvas element not found');
    }

    // Listen for route changes to close offcanvas and reset scroll
    this.$router.afterEach(() => {
      if (window.innerWidth <= 991) {
        const offcanvas = document.getElementById('offcanvasNavbar');
        if (offcanvas) {
          const offcanvasInstance = Offcanvas.getInstance(offcanvas);
          if (offcanvasInstance) {
            offcanvasInstance.hide();
          }
        }
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateLogo);
    $(window).off('scroll', this.handleScroll.bind(this));
  },
  methods: {
    updateLogo() {
      if (window.innerWidth <= 510) {
        this.logoSrc = alternateLogo;
        this.isAlternateLogo = true;
      } else {
        this.logoSrc = logo;
        this.isAlternateLogo = false;
      }
    },
    handleScroll() {
      if (window.innerWidth > 992) { // Only apply scroll behavior on larger screens
        if (window.scrollY > 0) {
          if (!this.isScrolled) {
            this.isScrolled = true;
            $('.navbar').hide().addClass('scrolled').slideDown(300);
          }
        } else {
          if (this.isScrolled) {
            this.isScrolled = false;
            $('.navbar').removeClass('scrolled');
          }
        }
      }
    }
  }
}

</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  background-color: #ffd5b8;
}

.img_logo {
  width: 445px;
  height: 52px;
}

.scrolled {
  border-bottom: 2px solid #26517c;
  transform: translateY(0);
}

@media (min-width:992px) {
  .nav-link {
    color: black;
    font-weight: 500;
    position: relative;
  }

  .nav-link:hover,
  .nav-link.active {
    color: #26517c;
    transition: all 0.3s ease-in-out;
  }

  .nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #26517c;
    visibility: hidden;
    transition: 0.3s ease-in-out;
  }

  .nav-link:hover::before,
  .nav-link.active::before {
    width: 100%;
    visibility: visible;
  }

  .dropdown-item {
    color: #fff;
    /* Set the text color for dropdown items */
  }

  .dropdown-item:hover {
    background-color: #f1f1f1;
    color: #26517c;
    /* Set the background color for hovered dropdown items */
  }

  .dropdown-menu {
    background-color: #26517c;
    /* Set the background color for dropdown menu */
    display: none;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }

  .dropdown-menu li {
    position: relative;
  }

  .dropdown .dropdown-menu .submenu {
    display: none;
    margin-top: 0;
    background-color: #26517c;
  }

  .submenu {
    position: absolute;
    top: 0;
    left: 100%;
  }

  .dropdown-menu>li:hover>a.dropdown-item {
    background-color: #f1f1f1;
    /* Maintain background color for hovered item */
    color: #26517c;
    /* Maintain text color for hovered item */
  }

  .scrolled {
    border-bottom: 2px solid #26517c;
    transform: translateY(0);
  }
}

@media (max-width:991px) {
  .offcanvas-title {
    display: none;
  }

  .offcanvas-header img {
    display: block;
    margin: 0 auto;
  }

  .offcanvas-body {
    text-align: left;
  }

  .navbar-nav {
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    text-align: left;
    width: 100%;
  }

  .dropdown-menu .submenu {
    display: none;
  }

  /* Menghilangkan warna biru saat dropdown aktif */
  .dropdown-menu .dropdown-item:active,
  .dropdown-menu .dropdown-item:focus,
  .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    color: inherit;
  }

  /* Menghilangkan warna biru saat link aktif atau fokus */
  .navbar-nav .nav-link:focus,
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link:active {
    color: inherit;
    background-color: transparent;
  }

  /* Menghilangkan warna biru pada level submenu */
  .dropdown-menu .submenu .dropdown-item:active,
  .dropdown-menu .submenu .dropdown-item:focus,
  .dropdown-menu .submenu .dropdown-item:hover {
    background-color: transparent;
    color: inherit;
  }
}

@media (min-width:511px) and (max-width:1215px) {
  .img_logo {
    width: 231px;
    height: 27px;
  }
}

@media (max-width:510px) {
  .img_logo.alternate-logo {
    width: 194px;
    height: 72px;
  }
}
</style>
