import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import 'bootstrap-icons/font/bootstrap-icons.css';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title';
    next();
});

app.use(router);
app.mount('#app');
