<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6 d-flex justify-content-center align-items-center mb-5">
        <div class="image-container">
          <a href="https://aksaraulu.com/"><img src="@/assets/logoaksara.png" class="icon_partnership pb-5"></a>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 d-flex justify-content-center align-items-center mb-5">
        <div class="image-container">
          <a href="https://www.instagram.com/dispustakaprovsumsel/"><img src="@/assets/disperpussumsel.png"
              class="icon_partnership pb-5"></a>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 d-flex justify-content-center align-items-center mb-5">
        <div class="image-container">
          <a href="https://www.instagram.com/disbudparsumsel/"><img src="@/assets/disbudparsumsel.png"
              class="icon_partnership pb-5"></a>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 d-flex justify-content-center align-items-center mb-5">
        <div class="image-container">
          <a href="https://www.instagram.com/dinaskearsipan.provsumsel/"><img src="@/assets/diskear.png"
              class="icon_partnership pb-5"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconMenu',
}
</script>

<style scoped>
.icon_partnership {
  max-width: 100%;
  height: auto;
  transition: .4s all;
}

.icon_partnership:hover {
  transform: scale(1.2);
}
</style>
