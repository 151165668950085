<template>
  <div class="crd_main container">
    <div class="row justify-content-center" id="card-container">
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/cover02.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">FGD Kearifan Lokal "Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan" dan
              Launching Aplikasi AKAS</h5>
            <p class="card-text mt-auto">Palembang. - Rabu, 03 Juli 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs05' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/cover01.jpg" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Workshop Forum Discussion Group (FGD) Pengenalan dan Penggunaan Aplikasi Aksara Ulu
              Oleh Intelligence System Research Group (ISRG) Universitas Bina Darma</h5>
            <p class="card-text mt-auto">Palembang. - Selasa, 14 November 2023 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs04' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/transliterasiaksara.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website</h5>
            <p class="card-text mt-auto">Palembang. - Kamis, 21 September 2023 Telah terjadi...</p>
            <div class="">
              <router-link class="btn btn_format" :to="{ name: 'NeWs03' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Additional cards initially hidden -->
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/alihaksara.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Aplikasi Alih Aksara Dokumen Aksara Ulu Berbasis Website</h5>
            <p class="card-text mt-auto">Palembang. - Sabtu, 16 September 2023 Telah terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs02' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/aksaraulu.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title">Aplikasi Aksara Ulu (Kanganga) di Platform Android</h5>
            <p class="card-text mt-auto">Palembang. - Selasa, 10 Oktober 2022 Telah terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs01' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- More button -->
    <div class="row mt-5">
      <div class="col-12 text-center my-5">
        <button id="show-more" class="btn btn_format">More</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsMenu',
  mounted() {
    const showMoreButton = document.getElementById('show-more');
    let currentCardIndex = 3;

    showMoreButton.addEventListener('click', function () {
      const hiddenCards = document.querySelectorAll('.col-md-4.d-none');
      for (let i = 0; i < 3 && currentCardIndex < hiddenCards.length + 3; i++) {
        hiddenCards[i].classList.remove('d-none');
        currentCardIndex++;
      }
      if (currentCardIndex >= hiddenCards.length + 3) {
        showMoreButton.style.display = 'none';
      }
    });
  }
}
</script>

<style scoped>
.card {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  border: none;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}

.card-text {
  font-size: 14px;
}

.crd_img {
  aspect-ratio: 3/2;
}

.btn_format {
  background-color: #ffd5b8;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: .4s all;
}

.btn_format:hover {
  transform: scale(1.2);
  color: #26517c;
}
</style>
