<template>
    <div class="mn_profile">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Profile</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container my-5">
            <div class="row">
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Intelligent Systems Research Group (ISRG) merupakan riset group yang berada di bawah program
                            studi Sistem Informasi, Fakultas Sains Teknologi Universitas Bina Darma, Palembang,
                            Indonesia. ISRG berdiri mulai tahun 2022 berdasarkan Surat Tugas No: 009/FST-UBD/III/2023.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            ISRG berfokus pada pengembangan teori dan aplikasi system yang bisa belajar, berpikir dan
                            bertindak secara cerdas.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Scope: interdisipliner riset yang memanfaatkan system cerdas dalam berbagai bidang Big Data
                            (bidang kesehatan, Keamanan & IoT, mitigasi Bencana, cuaca), budaya (aksara, Bahasa, arsip
                            daerah),
                            pemanfaatan bahasa alami dan berbagai bidang lainnya.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-5">
            <h4 class="my-3">Intelligent Systems Research Group</h4>
            <div class="row">
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/XQWhuPda-bk?si=fAOlITF0-8J34mID" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';

export default {
    name: 'ProFile',
    components: {
        NavBar,
        FooTer,
    },
};
</script>

<style scoped>
.mn_profile {
    margin-top: 140px;
}

.lg_img {
    width: 70px;
    height: 70px;
    aspect-ratio: 1/1;
}

.jmb_text {
    text-align: justify;
}

.nt-4 {
    margin: 0;
    line-height: 1.5;
}

.d-flex>.jmb_text {
    flex-grow: 1;
    padding-left: 20px;
}

.line_down {
    width: 35%;
}
</style>