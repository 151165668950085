<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>FGD Kearifan Lokal "Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan" dan
                    Launching Aplikasi AKAS</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Rabu 03 Juli 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/bannerakas.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Acara FGD Kearifan Lokal "Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan" dan
                        Launching Aplikasi AKAS terlaksana di Auditorium Graha Bina Praja, Palembang pada hari Rabu 03
                        Juli 2024. Acara ini mengundang banyak orang-orang penting dan pejabat di dalamnya. Acara ini
                        dihadiri oleh Pj Gubernur yang diwakili oleh Asisten II Bidang Ekonomi, Keuangan dan Pembangunan
                        yaitu Ir. Basyarudin Akhmad, M.Sc. Beliau secara langsung membuka acara FGD Kearifan Lokal
                        dengan Tema Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan sekaligus memimpin peluncuran
                        Aplikasi Aksara Ulu Sumatera Selatan (AKAS). Selain itu, acara ini juga dihadiri oleh Duta
                        Literasi dr. Ratu Tenny Leriva, M.M., Kepala Dinas Kebudayaan dan Pariwisata Provinsi Sumatera
                        Selatan Dr. H. Aufa Syahrizal, SP., M.Sc., Sekretaris Dinas Kebudayaan dan Pariwisata Dr. Hj.
                        Mega Nugraha, S.H., M.Si., Kepala Bidang Dokumentasi dan Publikasi Budaya yaitu Hj. Vinita Citra
                        Karini, S.E., M.Si. selaku Ketua Pelaksana, Kepala Bidang Destinasi yaitu Vita Sandra, S.Pd.,
                        M.Si., Kepala Bidang Kebudayaan Cahyo Sulistyaningsih, S.Sos., Para pejabat eselon IV, staff di
                        Lingkungan Dinas Kebudayaan dan Pariwsata Provinsi Sumatera Selatan, Anggota Komunitas Pecinta
                        Aksara Ulu, Rektor Universitas Bina Darma yaitu Prof. Dr. Sunda Ariana, M.Pd., M.M. dan Tim ISRG
                        Universitas Bina Darma.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Acara ini mulai berlangsung pukul 08.00 WIB - 13.00 WIB. Rangkaian acara yang dilakukan mulai
                        dari penyanyian lagu Indonesia Raya secara bersama-sama, pembukaan, penyampaian kata sambutan
                        oleh beberapa pihak, pembacaan doa, penandatanganan MoU antara Pihak Universitas Bina Darma dan
                        Pemerintah Provinsi Sumatera Selatan, acara launching AKAS, acara penyampaian materi, acara
                        forum diskusi dan closing.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/opening.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <a href="https://play.google.com/store/apps/details?id=id.sumsel.akas&hl=en-ID"><img
                                src="@/assets/logoakas.jpg " class="nws_img01"></a>
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/penandatangananmou.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/penandatangananmou02.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Dalam sesi materi, terdapat 3 sesi materi yang dilakukan. Sesi pertama berupa materi pengantar
                        tentang Aksara Ulu yang disampaikan oleh Nuzulur Ramadhona, M. Hum. Kemudian, sesi materi kedua
                        yaitu tentang Aksara Ulu lebih dalam yang disampaikan oleh Drs. Ahmad Rapanie Igama.
                        Selanjutnya, sesi ketiga materi tentang aplikasi AKAS sekaligus pandangan tenaga IT terhadap
                        Aksara Ulu yang disampaikan oleh Ketua Riset Intelligence Systems Research Group (ISRG) yaitu
                        Dr. Yesi Novaria Kunang, S.T., M.Kom. Setelah sesi materi selesai dan acara launching AKAS telah
                        dilaksanakan, acara dilanjutkan ke sesi forum diskusi bersama. Dalam acara diskusi ini peserta
                        dapat mengajukan pertanyaan kepada pemateri untuk didiskusikan jika terdapat hal-hal yang belum
                        dimengerti. Selain rangkaian kegiatan tersebut, dalam acara ini juga dilakukan sesi photo
                        bersama.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/sesimaterifgd.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/sesidiskusifgd.jpg " class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersamafgd.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersamafgd02.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photobersamafgd03.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs04' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs01' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs05',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
