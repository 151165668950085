<template>
    <div class="mn_us">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Research Student</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="crd_main container">
            <div class="row" id="card-container">
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Doni Indrian Edo</h5>
                                <h5 class="card-title">201410150</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">M. Fikri</h5>
                                <h5 class="card-title">101410102</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Rizki Ramadhan</h5>
                                <h5 class="card-title">201410016</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Liu Toriko</h5>
                                <h5 class="card-title">201410064</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">M. Iqbal Prasetya</h5>
                                <h5 class="card-title">201410114</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Al Hafiz</h5>
                                <h5 class="card-title">201410130</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Muhammad Finaldo</h5>
                                <h5 class="card-title">201410129</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Bogy Dharma Sandi</h5>
                                <h5 class="card-title">201410145</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Laode Hengky Pandrawan</h5>
                                <h5 class="card-title">201410038</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Muhammad Gusti Samar Qandhi</h5>
                                <h5 class="card-title">201410159</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Syafaat Prasetia</h5>
                                <h5 class="card-title">201410106</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Andre Fernando</h5>
                                <h5 class="card-title">201410084</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Aji Nugraha Saleh</h5>
                                <h5 class="card-title">201410043</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/anonim.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Fatihah Prima Ully</h5>
                                <h5 class="card-title">21142014P</h5>
                                <p class="card-text">On Progress</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/andika.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Muhamad Andika</h5>
                                <h5 class="card-title">191410165</h5>
                                <p class="card-text">Model Mesin Penerjemah Bahasa Besemah Berbasis Machine Learning
                                    Dengan Algoritma Model Recurrent Neural Network (RNN)</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/surya.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Surya Mardani</h5>
                                <h5 class="card-title">191410129</h5>
                                <p class="card-text">Machine Translation Model of Besemah Language Based on Machine
                                    Learning with Bidirectional RNN Algorithm</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2024</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/tia.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Tia Permata Sari</h5>
                                <h5 class="card-title">191410180</h5>
                                <p class="card-text">Pengembangan Aplikasi Transliterasi Teks Latin ke Aksara Ulu
                                    (Komering) Berbasis Web.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/wulan.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Wulan Sapitri</h5>
                                <h5 class="card-title">191410226</h5>
                                <p class="card-text">The Impact of Data Augmentation Techniques on the Recognition of
                                    Script
                                    Images in Deep Learning Models.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/nia.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Nia Taresta Wulandari</h5>
                                <h5 class="card-title">191410176</h5>
                                <p class="card-text">Indonesian Dewey Decimal Classification System Using Support Vector
                                    Machine and Neural Network Algorithms.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/irfan.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Irfan Henuarianto</h5>
                                <h5 class="card-title">191410157</h5>
                                <p class="card-text">Komering-Indonesian Machine Translation Using Embedding RNN.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/ahmad.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Ahmad Subhan</h5>
                                <h5 class="card-title">191410181</h5>
                                <p class="card-text">Analyzing the Attack Pattern of Brute Force Attack on SSH Port.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/rizki.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">M. Rizki Fadilah</h5>
                                <h5 class="card-title">191410266</h5>
                                <p class="card-text">Machine Learning-Based Komering Language Translation Engine with
                                    Bidirectional RNN Model Algorithm.</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/dio.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Muhammad Dio Hamanrora</h5>
                                <h5 class="card-title">191410168</h5>
                                <p class="card-text">Image segmentation of Komering script using bounding box</p>
                                <p class="card-text mt-auto">Status (Completed)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/tri.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Tri Rahmawati</h5>
                                <h5 class="card-title">191410030</h5>
                                <p class="card-text">Machine Learning Based Komering Language Interpreter with
                                    Encoder-Decoder Model Algorithm</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/aji.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Aji Awang Razio</h5>
                                <h5 class="card-title">191410207</h5>
                                <p class="card-text">Implementation Of Neural Machine Transliteration
                                    Of Komering Languages To Indonesian Using
                                    Recurrent Neural Network (Rnn) Model</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/selvi.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Selvi Angraini</h5>
                                <h5 class="card-title">191410170</h5>
                                <p class="card-text">Implementation of Naïve Bayes Algorithm and
                                    Logistic Regression Dewey Decimal Classification</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="card h-100">
                        <div class="card-top d-flex justify-content-center">
                            <img src="@/assets/raffi.jpg" class="card-img-top crd_img" alt="..." />
                        </div>
                        <div class="card-bottom d-flex flex-column">
                            <div class="card-body d-flex flex-column">
                                <h5 class="card-title">Raffi Nurmaysi</h5>
                                <h5 class="card-title">191410097</h5>
                                <p class="card-text">Pengembangan Sistem Repository Buku Digital Dengan Penerapan
                                    Regulasi Proteksi Data</p>
                                <p class="card-text mt-auto">Status (On Progress)</p>
                                <h5 class="card-title">Tahun 2023</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'ResearchStudent',
    components: {
        NavBar,
        FooTer,
    },
}
</script>


<style scoped>
.mn_us {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.card {
    border-radius: 30px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border: none;
}

.crd_img {
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 150px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 30px;
    border: 3px solid #26517c;
    object-fit: cover;
}

.card-title {
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
}

.card-text {
    margin-top: 15px;
    text-align: justify;
    font-size: 12px;
}

.card-top {
    background-color: #ffd5b8;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.card-bottom {
    background-color: white;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    flex-grow: 1;
}

@media (min-width: 768px) and (max-width: 992px) {
    .crd_img {
        max-width: 100px;
    }
}

@media (max-width: 767px) {
    .crd_img {
        max-width: 160px;
    }
}
</style>
