<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Aplikasi Alih Aksara Dokumen Aksara Ulu Berbasis Website</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Sabtu 16 September 2023</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/alihaksara.png" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Aplikasi Alih Aksara Dokumen Aksara Ulu berbasis Website ini berguna untuk memisahkan karakter
                        aksara ulu secara satu persatu yang terdapat dalam gambar dokumen aksara ulu. Selain itu, akan
                        dilakukan prediksi terhadap huruf aksara komering yang terdeteksi di dalam gambar untuk dikenali
                        huruf latin dari karakter aksara ulu yang terdeteksi tersebut. Prosesnya dimulai dengan
                        mengupload gambar dokumen aksara ulu. Setelah itu akan dilakukan pre-processing terlebih dahulu
                        terhadap gambar yang telah diupload. Pre-processing yang dilakukan berupa normalization yang
                        terdiri dari 2 jenis normalization yaitu erosion dan dilation. Erosion sendiri berguna untuk
                        menipiskan nilai piksel sedangkan dilation sendiri berguna untuk mempertebal nilai piksel.
                        Pilihan normalization ini disesuaikan dengan gambar dokumen aksara yang diupload. Setelah
                        pre-processing, tahap selanjutnya yaitu pendeteksian karakter aksara ulu yang terdapat digambar.
                        Kemudian tahap akhir yaitu prediksi huruf latin dari setiap karakter aksara ulu yang terdeteksi
                        di gambar. Berikut link untuk membuka Aplikasi Alih Aksara Dokumen Aksara Ulu Berbasis Website.
                        <a href="https://enggine.nulisaksaraulu.my.id/smart-prediction">Link</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Adapun video tutorial terkait penggunaan aplikasi alih aksara dokumen aksara ulu berbasis
                        website dapat
                        dilihat melalui video tutorial di youtube berikut.
                    </p>
                </div>
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/iGAOtZvhf0k?si=fTOsYIDqNuwEHXYV" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs01' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs03' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs02',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 14px;
    text-align: justify;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
