<template>
  <div id="carouselExampleCaptions" class="carousel slide">
    <div class="c-ind carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
        aria-label="Slide 3"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
        aria-label="Slide 4"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"
        aria-label="Slide 5"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5"
        aria-label="Slide 6"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active c-item">
        <img src="@/assets/slider01.png" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Healthcare</h5>
          <p>Kecerdasan buatan memanfaatkan data besar untuk menganalisis pola kesehatan, memprediksi penyakit, dan
            memberikan perawatan yang lebih personal dan efektif.</p>
        </div>
      </div>
      <div class="carousel-item c-item">
        <img src="@/assets/slider02.jpg" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Cyber Security & IoT</h5>
          <p>Data besar mengoptimalkan keamanan siber dengan mendeteksi ancaman siber dan mengelola perangkat IoT,
            memastikan lingkungan digital yang aman dan terkendali.</p>
        </div>
      </div>
      <div class="carousel-item c-item">
        <img src="@/assets/slider03.jpg" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Disaster Mitigation</h5>
          <p>Sistem cerdas menggunakan data besar untuk dengan cepat memprediksi dan merespons bencana alam, membantu
            dalam mitigasi risiko dan menyelamatkan nyawa.</p>
        </div>
      </div>
      <div class="carousel-item c-item">
        <img src="@/assets/slider04.jpg" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Weather Mitigation</h5>
          <p>Analisis data besar meningkatkan akurasi prediksi cuaca, memungkinkan perencanaan yang lebih baik dan
            respons cepat terhadap perubahan cuaca ekstrem.</p>
        </div>
      </div>
      <div class="carousel-item c-item">
        <img src="@/assets/slider05.jpg" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Local Culture Preservation</h5>
          <p>Sistem cerdas melestarikan budaya lokal dengan mendigitalkan naskah, bahasa, dan arsip regional, memastikan
            warisan budaya tetap hidup dan dapat diakses oleh generasi mendatang.</p>
        </div>
      </div>
      <div class="carousel-item c-item">
        <img src="@/assets/slider06.jpg" class="d-block w-100 c-img" alt="slider_img">
        <div class="c-c carousel-caption">
          <h5>Intelligent Systems for Real-Time Applications</h5>
          <p>
            Teknologi real-time memanfaatkan data besar untuk memberikan respons dan analisis langsung dalam berbagai
            aplikasi, mulai dari transportasi hingga perdagangan saham, memastikan pengambilan keputusan yang cepat dan
            akurat.</p>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CarOusel',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.c-ind {
  margin-bottom: 75px;
}

.c-c {
  margin-bottom: 3rem;
}

.c-img {
  height: 100%;
  filter: brightness(0.6);
  aspect-ratio: 8/3.21;
}

.carousel-caption h5,
.carousel-caption p {
  color: white;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

@media (max-width:510px) {
  .c-ind {
    margin-bottom: 20px;
  }

  .c-c {
    margin-bottom: -5px;
  }

  h5 {
    font-size: 10px;
  }

  p {
    font-size: 7px;
  }
}

@media (min-width:511px) and (max-width:768px) {
  .c-ind {
    margin-bottom: 40px;
  }

  .c-c {
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 13px;
  }
}
</style>
