<template>
    <div class="mn_rp">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Researchers Profile</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container mt-5">
            <table class="table table-responsive table-hover">
                <thead class="text-uppercase text-center align-middle">
                    <tr>
                        <th>No</th>
                        <th>Profile</th>
                        <th>Lecture Name</th>
                        <th>Research Topic</th>
                        <th>Publication Link</th>
                    </tr>
                </thead>
                <tbody class="text-left">
                    <tr>
                        <td>1</td>
                        <td><img src="@/assets/yesi.jpg" alt="Profile Image" class="profile-img"></td>
                        <td>Dr. Yesi Novaria Kunang, S.T., M.Kom.</td>
                        <td>Intelligent System, Machine Learning and Deep Learning, Data Science, Information Security,
                            Forensic Computer.
                        </td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://www.scopus.com/authid/detail.uri?authorId=57206723142"><img
                                        src="@/assets/scopus.png" alt="SCOPUS" class="icon_publication"></a>
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/5975782"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.com/citations?user=a1jsnhUAAAAJ&hl=en"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><img src="@/assets/ilman.jpg" alt="Profile Image" class="profile-img"></td>
                        <td>Ilman Zuhri Yadi, M.Kom., M.M.</td>
                        <td>Intelligent System, Information Security.</td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://www.scopus.com/authid/detail.uri?authorId=58144190500"><img
                                        src="@/assets/scopus.png" alt="SCOPUS" class="icon_publication"></a>
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/6003397"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.com/citations?hl=en&user=9XjBMnsAAAAJ"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><img src="@/assets/susan.jpg" alt="Profile Image" class="profile-img"></td>
                        <td>Susan Dian Purnamasari, M.Kom.</td>
                        <td>Database, Software Engineering, Business Intelligence, Data Science.</td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://www.scopus.com/authid/detail.uri?authorId=57206721131"><img
                                        src="@/assets/scopus.png" alt="SCOPUS" class="icon_publication"></a>
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/5976208"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.com/citations?user=QBrVL3YAAAAJ&hl=en&oi=ao"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><img src="@/assets/leon.jpg" alt="Profile Image" class="profile-img"></td>
                        <td>Assoc. Prof. Leon A. Abdillah, S.Kom., M.M., MTA, CDM</td>
                        <td>E-Learning, Knowledge Management.</td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://www.scopus.com/authid/detail.uri?authorId=57200984011"><img
                                        src="@/assets/scopus.png" alt="SCOPUS" class="icon_publication"></a>
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/255727"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.com/citations?user=BXFFHDMAAAAJ&hl=en"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><img src="@/assets/ferdiansyah.jpeg" alt="Profile Image" class="profile-img"></td>
                        <td>Ferdiansyah, M.Kom.</td>
                        <td>Data Science, IT Security, IT Forensic, Block-Chain.</td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/6172583"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.co.id/citations?user=yczuDDAAAAAJ&hl=id"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td><img src="@/assets/fitri.jpg" alt="Profile Image" class="profile-img"></td>
                        <td>Fitri Purwaningtias, M.Kom.</td>
                        <td>Data Science.</td>
                        <td class="text-center">
                            <div class="icon-container">
                                <a href="https://sinta.kemdikbud.go.id/authors/profile/6003430"><img
                                        src="@/assets/sinta.png" alt="SINTA" class="icon_publication"></a>
                                <a href="https://scholar.google.co.id/citations?user=g_0Ec1AAAAAJ&hl=en"><img
                                        src="@/assets/scholar.png" alt="Scholar" class="icon_publication"></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <FooTer />
    </div>
</template>


<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'ResearchersProfile',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_rp {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

thead th {
    text-align: center;
    vertical-align: middle;
    background-color: #ffd5b8;
}

.table-hover td {
    text-align: left;
}

.profile-img {
    width: 100%;
    max-width: 100px;
    object-fit: cover;
    aspect-ratio: 4/6;
}

.icon-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.icon_publication {
    aspect-ratio: 1/1;
    max-width: 24px;
    max-height: 24px;
    transition: .3s all;
}

.icon_publication:hover {
    transform: scale(1.1);
}

@media (max-width: 500px) {
    table {
        font-size: 9px;
    }

    th,
    td {
        padding: 5px;
    }

    h1 {
        font-size: 24px;
    }

    .profile-img {
        max-width: 50px;
    }

    .icon-container {
        gap: 5px;
    }

    .icon_publication {
        max-width: 16px;
        max-height: 16px;
    }
}
</style>
