<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Kamis 21 September 2023</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/berandatransliterasi.png" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website memiliki 3 menu yang dapat
                        digunakan. Menu pertama berupa halaman utama. Dalam halaman ini terdapat 2 informasi yang dapat
                        dibaca oleh pengguna yaitu sejarah singkat aksara ulu dan asal nama aksara ulu itu sendiri. 2
                        menu lainnya yaitu transliterasi yang berupa menu utama untuk menerjemahkan huruf latin menjadi
                        aksara ulu dan menu aksara untuk melihat keterangan huruf aksara ulu yang diartikan huruf latin.
                        Berikut link untuk mengakses Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website.
                        <a href="https://nulisaksaraulu.my.id/">Link</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Pada menu transliterasi, pengguna dapat menerjemahkan huruf latin menjadi aksara ulu. Pengguna
                        dapat mengisi form transliterasi yang disediakan untuk menerjemahkan huruf latin menjadi aksara
                        ulu sesuai keinginan. Pengguna dapat menginputkan huruf, kata, kalimat bahkan kalimat panjang
                        sekalipun. Sistem akan memproses terjemahan sesuai dengan inputan yang diberikan pengguna
                        sekaligus jika inputan tersebut cukup panjang. Menu aksara sendiri berupa kamus aksara ulu ke
                        latin. Jadi setiap huruf-huruf aksara ulu yang ada akan diterjemahkan ke huruf latinnya.
                        Terdapat juga fitur pencarian di menu ini sehingga dapat memudahkan pengguna untuk mencari huruf
                        aksara ulu yang diinginkan.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/transliterasiaksara.png" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/halhurufaksara.png " class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Adapun video tutorial terkait penggunaan aplikasi transliterasi aksara latin ke aksara ulu
                        berbasis website dapat
                        dilihat melalui video tutorial di youtube berikut.
                    </p>
                </div>
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/puZwdqgdmwo?si=qF6iiR108zyhoUgP" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs02' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs04' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs03',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 14px;
    text-align: justify;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
